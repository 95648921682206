import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../layouts/MainLayout"

function AboutUs() {
  return (
    <Layout>
      <div className="banner"> About Us</div>
      <div className="container my-5">
        <div className="d-flex flex-lg-nowrap flex-wrap align-items-center my-5">
          <p className="flex-half p-4">
            <span className="fs-3">W</span>ithin a decade of the establishment
            of the IEEE Student Branch in College of Engineering, Vadakara, Our
            Branch is said to be one of the active working SB in the Kerala
            State. Now IEEE SB CEV has a total of 70+ Student Members and 
            Graduate Student Members. Our SB also has its three active societies, IEEE
            Computer Society with 50+ Active Members The Power Energy Society
            with 20+ Members , IEEE Education Society and also the Women in Engineering Affinity Group
            (WiE AG) to motivate and support the young and talented students to
            achieve their greater future and career .<br />
            <br />
            Our branch also holds many programmes and events at different levels
            like the Fledge and TeqWiEn that have attained a huge success and
            had gained enough outcomes on students.
            <br />
            <br />
          </p>
          <StaticImage
            className="flex-half rounded-xl"
            src="../images/About-2.jpg"
            alt=""
          />
        </div>
        <div className="d-flex flex-lg-nowrap flex-wrap flex-row-reverse align-items-center my-5">
          <p className="flex-half p-4">
            Besides, we also have our very own active members who had
            participated in Regional, Sectional, Hub and Student Branch level
            and had gained a great amount of experiences & honorable
            recognitions.
            <br />
            <br />
            So here in IEEE SB CEV, we provide a platform to inculcate and
            blossom new technical and innovative ideas in the minds of these
            young engineers to design a creative and better world.
            <br />
            <br />
            Alone we can do little, together we can do too much. Are you
            interested in joining our most prestigious community, where we can
            become the Engineers of a better future.
            <br />
            <br />
          </p>
          <StaticImage
            className="flex-half rounded-xl"
            src="../images/About-3.jpg"
            alt=""
          />
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
